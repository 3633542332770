import './ExploreContainer.css';
import React, { useState, useEffect } from 'react';
import { IonImg, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonButton, IonAlert, IonFooter, IonToolbar, IonTitle } from '@ionic/react';
import { enterOutline, exitOutline } from 'ionicons/icons';
import { Device } from '@capacitor/device';
import { Geolocation } from '@capacitor/geolocation';

interface ContainerProps { }

// define img obkect and its properties
type Image = {
  src: string;
  text: string;
};
const image: Image = { src: 'https://www.isomec-service.com/img/isomec-simple.png', text: 'Isomec logo' };

const ExploreContainer = ({deviceId}: any) => {

  // define the alert dialogues
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [alertTit, setAlertTit] = useState("-");
  const [alertText, setAlertText] = useState("-");


  // async function getCurrentPosition() // equivalent to...
  const getCurrentPosition = async () =>
  {
    const coordinates = await Geolocation.getCurrentPosition();
    console.log('getCurrentPosition:: ', coordinates.coords.latitude);
    setLat(coordinates.coords.latitude);
    setLng(coordinates.coords.longitude);
  };

  // call function to retrieve the device UUID
  useEffect(() => {
   getCurrentPosition();
  });

  function handleClick(action: number)
  {
    //https://jsonplaceholder.typicode.com/todos
    const url = `https://www.isomec-service.com/server/punchServer.php?act=${action}&uuid=${deviceId}&lat=${lat}&lng=${lng}&h=4cdbWJaX`;
    console.log("handleClick:: ", url);
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setAlertTit(result.tit)
          setAlertText(result.text)
          setShowAlert1(true)
        },
        (error) => {
          console.log('Problema nell\'invio dei dati: ',error);
          setShowAlert2(true)
        }
    )
  }

  return (
    <div>
      <div className="topContainer">
        <IonImg src={image.src} />
      </div>

      <div className="container">
        <IonGrid fixed={true}>
          <IonRow>
            <IonCol class="ion-margin-bottom">
              <IonButton color="success" size="large" expand="block" onClick={() => handleClick(1)}>
                <strong>Marca Ingresso</strong>
                <IonIcon slot="start" icon={enterOutline} class="ion-padding" size="large"/>
              </IonButton>
            </IonCol>
            <IonCol class="ion-margin-top">
              <IonButton color="warning" size="large" expand="block" onClick={() => handleClick(2)}>
                <strong>Marca Uscita</strong>
                <IonIcon slot="end" icon={exitOutline} class="ion-padding" size="large"/>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonAlert
          header={alertTit}
          isOpen={showAlert1} message={alertText} buttons={[{ text: 'Ok' }]} onDidDismiss={() => setShowAlert1(false)} />
      <IonAlert
        header={'Errore di registrazione'}
        isOpen={showAlert2} message="C'è stato un problema! Per favore riavvia l'app e riprova." buttons={[{ text: 'Ok' }]} onDidDismiss={() => setShowAlert2(false)} />
    </div>
  );
};

export default ExploreContainer;
