import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter, IonAlert } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { Device } from '@capacitor/device';
import ExploreContainer from '../components/ExploreContainer';

import './Home.css';

const Home: React.FC = () => {

  // define the alert dialogues
  const [showAlert0, setShowAlert0] = useState(false);
  const [deviceId, setDeviceId] = useState("-");

    // retrieve the device UUID
    const getDeviceInfo = async () => {
      const devId = await Device.getId();
      setDeviceId(devId.uuid);
    };

    // call function to retrieve the device UUID
    useEffect(() => {
     getDeviceInfo();
    });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Marca l'ingresso o l'uscita</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="small">Marca l'ingresso o l'uscita</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer deviceId={deviceId}/>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonTitle className="footerText" onClick={() => { navigator.clipboard.writeText(deviceId); setShowAlert0(true);}}>{deviceId}</IonTitle>
        </IonToolbar>
      </IonFooter>

      <IonAlert
          header="Codice copiato"
          isOpen={showAlert0} message="Ora potete mandare il codice incollandolo in un messaggio." buttons={[{ text: 'Ok' }]} onDidDismiss={() => setShowAlert0(false)} />
    </IonPage>
  );
};

export default Home;
